<template>
  <div class="contain">
    <searchBar :searchInput="false"></searchBar>
    <div class="bgf flex_c upload-cart">
      <div class="flex_1" style="width: 670px">
        <el-steps :active="steps" align-center style="width: 840px; margin: 0 auto">
          <el-step
            title="上传文件"
            description="请上传指定格式文件"
            :class="{ in: steps >= 2 }"
          ></el-step>
          <el-step
            title="校验数据"
            description="检查数据格式是否正确"
            :class="{ in: steps >= 3 }"
          ></el-step>
          <el-step
            title="写入数据"
            description="正在执行写入数据"
            :class="{ in: steps >= 4 }"
          ></el-step>
          <el-step
            title="上传资料"
            description="请上传指定格式文件"
            :class="{ in: steps >= 5 }"
            v-if="importType == 'bussiness'"
          ></el-step>
        </el-steps>
        <template v-if="steps == 1">
          <div style="position: relative">
            <el-upload
              ref="upload"
              class="upload"
              accept=".xlsx"
              drag
              :action="`${baseApi}/ent/v3/${importType}/import`"
              :headers="headers"
              :limit="2"
              :on-change="onChange"
              :auto-upload="false"
              :on-error="onError"
              :on-remove="onRemove"
              :on-success="handleUploadSuccess"
            >
              <img src="@/assets/imgs/icon/upload-icon.png" class="icon" alt="" />
              <div class="el-upload__text">
                <!-- <div class="color fs_16">上传文件代表审核已离职的清单</div> -->
                <el-button type="primary">上传文件代表审核已离职的清单</el-button>
                <div class="el-upload__tip">
                  <p>上传文件格式仅支持xls，xlsx，且文件大小不能超过5M，</p>
                  <p>请按照规定格式填写数据，每次填写数据总和不超过10000条</p>
                </div>
              </div>
            </el-upload>
            <a
              v-if="importType == 'dimission'"
              :href="'/file/离职清单导入模板.xlsx'"
              download=""
              class="download-btn color fs_14"
              >下载模版</a
            >
            <a
              v-else-if="importType == 'reward'"
              :href="'/file/灵活用工支出记录导入模板.xlsx'"
              download=""
              class="download-btn color fs_14"
              >下载模版</a
            >
            <a
              v-else
              :href="'/file/工商办理导入模板.xlsx'"
              download=""
              class="download-btn color fs_14"
              >下载模版</a
            >
          </div>
          <div class="flex_cen">
            <el-button
              type="primary"
              class="next-btn"
              :disabled="!hasFile"
              :loading="sandLoading"
              @click="handleNext"
              >下一步</el-button
            >
          </div>
        </template>

        <template v-if="steps == 2">
          <div class="fs_12 info_color mb_15" style="margin-top: 60px">
            共导入数据 {{ excelData.list.length }} 条，其中有
            {{ excelData.error_num }} 条数据错误
          </div>
          <el-form ref="forms" :model="forms">
            <!-- 离职证明批量导入数据 -->
            <div v-if="importType == 'dimission'">
              <el-table
                :data="forms.tableData"
                style="width: 100%"
                :header-cell-style="{ 'text-align': 'center' }"
                :row-style="rowStyle"
              >
                <el-table-column
                  label="序号"
                  width="60"
                  type="index"
                  align="center"
                ></el-table-column>
                <el-table-column label="姓名" width="80">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.realname">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.realname }"
                        v-model.trim="row.realname"
                        @input="row.realname = textFilter(row.realname)"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="手机号" width="105">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.mobile">
                      <el-input
                        type="number"
                        :disabled="disabled"
                        v-model.trim="row.mobile"
                        :maxlength="11"
                        :class="{ err: row.error && !row.error.mobile }"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="身份证号" width="160">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.idcard">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.idcard }"
                        v-model.trim="row.idcard"
                        @change="row.idcard = row.idcard.toUpperCase()"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="所在部门" width="120">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.dept_name">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.dept_name }"
                        v-model.trim="row.dept_name"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="担任职位" width="80">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.job_name">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.job_name }"
                        v-model.trim="row.job_name"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="员工类型" width="100">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.type">
                      <el-select
                        v-model.trim="row.type"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.type }"
                        placeholder="请选择"
                      >
                        <el-option label="正式员工" value="正式员工"></el-option>
                        <el-option label="外聘" value="外聘"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="离职方式" width="120">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.method">
                      <!-- <el-input type="text" :disabled="disabled" :class="{err: row.error&&!row.error.method}" v-model.trim="row.method"></el-input> -->
                      <el-select
                        v-model.trim="row.method"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.method }"
                        placeholder="请选择"
                      >
                        <el-option label="协商解除" value="协商解除"></el-option>
                        <el-option
                          label="劳动者单方解除"
                          value="劳动者单方解除"
                        ></el-option>
                        <el-option
                          label="用人单位单方解除"
                          value="用人单位单方解除"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="入职时间" width="100">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.start_time">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.start_time }"
                        v-model.trim="row.start_time"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="离职时间" width="100">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.end_time">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.end_time }"
                        v-model.trim="row.end_time"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="错误原因">
                  <template #default="{ row }">
                    <div class="center">
                      <p
                        style="color: #f56c6c"
                        v-for="(item, index) in row.error.msg_list"
                        :key="index"
                      >
                        {{ item }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 灵活用工支出记录导入模板数据 -->
            <div v-if="importType == 'reward'">
              <el-table
                :data="forms.tableData"
                style="width: 100%"
                :header-cell-style="{ 'text-align': 'center' }"
                :row-style="rowStyle"
              >
                <el-table-column
                  label="序号"
                  width="60"
                  type="index"
                  align="center"
                ></el-table-column>
                <el-table-column label="姓名" width="80">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.realname">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.realname }"
                        v-model.trim="row.realname"
                        @input="row.realname = textFilter(row.realname)"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="手机号">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.mobile">
                      <el-input
                        type="number"
                        :disabled="disabled"
                        v-model.trim="row.mobile"
                        :maxlength="11"
                        :class="{ err: row.error && !row.error.mobile }"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="身份证号">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.idcard">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.idcard }"
                        v-model.trim="row.idcard"
                        @change="row.idcard = row.idcard.toUpperCase()"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="企业支出">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.total_amount">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.total_amount }"
                        v-model.trim="row.total_amount"
                        @change="row.total_amount = row.total_amount.toUpperCase()"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="错误原因">
                  <template #default="{ row }">
                    <div class="center">
                      <p
                        style="color: #f56c6c"
                        v-for="(item, index) in row.error.msg_list"
                        :key="index"
                      >
                        {{ item }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 工商注册批量导入数据 -->
            <div v-if="importType == 'bussiness'">
              <el-table
                :data="forms.tableData"
                style="width: 100%"
                :header-cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column
                  label="序号"
                  width="60"
                  type="index"
                  align="center"
                ></el-table-column>
                <el-table-column label="姓名" width="80">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.realname">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.realname }"
                        v-model.trim="row.realname"
                        @input="row.realname = textFilter(row.realname)"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="手机号" width="105">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.mobile">
                      <el-input
                        type="number"
                        :disabled="disabled"
                        v-model.trim="row.mobile"
                        :maxlength="11"
                        :class="{ err: row.error && !row.error.mobile }"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="身份证号" width="160">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.idcard">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.idcard }"
                        v-model.trim="row.idcard"
                        @change="row.idcard = row.idcard.toUpperCase()"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="申请类型" width="135">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.apply_type">
                      <el-select
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.apply_type }"
                        v-model.trim="row.apply_type"
                        placeholder="请选择"
                      >
                        <el-option label="个体工商户" value="1"></el-option>
                        <el-option label="个人独资企业" value="2"></el-option>
                        <!-- <el-option label="有限合伙企业" value="3"></el-option> -->
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="字号一" width="170">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.apply_name_1">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.apply_name_1 }"
                        v-model.trim="row.apply_name_1"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="字号二" width="170">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.apply_name_2">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.apply_name_2 }"
                        v-model.trim="row.apply_name_2"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="字号三" width="170">
                  <template #default="{ row }">
                    <el-form-item :rules="rules.apply_name_3">
                      <el-input
                        type="text"
                        :disabled="disabled"
                        :class="{ err: row.error && !row.error.apply_name_3 }"
                        v-model.trim="row.apply_name_3"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="错误原因">
                  <template #default="{ row }">
                    <div class="center">
                      <p
                        style="color: #f56c6c"
                        v-for="(item, index) in row.error.msg_list"
                        :key="index"
                      >
                        {{ item }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="flex_cen mt_15">
              <el-button type="info" class="next-btn mr_20" @click="handlePre"
                >上一步</el-button
              >
              <el-button
                type="primary"
                class="next-btn"
                :loading="sandLoading"
                @click="ruleForms(excelData.error_num > 0 ? 'before_check' : 'batch_add')"
                >{{ excelData.error_num > 0 ? "重新校验" : "导 入" }}</el-button
              >
            </div>
          </el-form>
        </template>

        <template v-if="steps == 3">
          <div class="center">
            <img src="../../assets/imgs/succ.png" class="success" alt="" />
            <p class="fs_20 bold color">数据导入成功</p>
            <p class="fs_14 info_color mt_10" v-if="importType == 'dimission'">
              成功导入数据 {{ excelData.success_num }} 条，其中自动审核通过{{
                excelData.check_num
              }}条，{{ excelData.wait_num }}条进入[待匹配]列表
            </p>
            <p class="fs_14 info_color mt_10" v-else>
              成功导入数据 {{ excelData.success_num }} 条
            </p>
          </div>
          <div class="flex_cen mt_15">
            <el-button
              type="primary"
              class="next-btn"
              @click="backList"
              v-if="importType == 'dimission'"
              >返 回</el-button
            >
            <el-button type="primary" class="next-btn" @click="steps++" v-else
              >上传资料</el-button
            >
          </div>
        </template>

        <template v-if="steps == 4">
          <el-upload
            ref="dataUpload"
            class="upload"
            accept=".zip,.rar"
            drag
            :auto-upload="false"
            :action="`${baseApi}/ent/v3/bussiness/batch/import/${import_id}`"
            :headers="headers"
            :limit="2"
            :on-change="dataFileChange"
            :on-error="onError"
            :on-remove="onDataRemove"
            :on-success="dataUploadSuccess"
          >
            <img src="@/assets/imgs/icon/upload-icon.png" class="icon" alt="" />
            <div class="el-upload__text">
              <div class="color fs_16">上传资料</div>
              <div class="el-upload__tip">
                <p>上传资料格式仅支持 rar、zip，且文件大小不能超过200M</p>
              </div>
            </div>
          </el-upload>
          <div class="flex_cen tip-box">
            <div>
              <p class="center bold">身份证资料打包示例</p>
              <p class="fs_14 color_666 padding_10 mt_20">
                步骤一：将人员身份证正反面按【姓名-身份证-正 / 反】命名规则
              </p>
              <img src="@/assets/imgs/tip-img.png" alt="" class="" />
              <p class="fs_14 color_666 padding_10 mt_20">
                步骤二：将此文件夹打包压缩为 rar / zip 格式，上传到平台
              </p>
              <img src="@/assets/imgs/zip-tip.png" alt="" class="" style="width: 16%" />
            </div>
          </div>
          <div class="flex_cen">
            <el-button type="info" class="next-btn mr_20" @click="backList"
              >稍后上传</el-button
            >
            <el-button
              type="primary"
              class="next-btn"
              :disabled="!hasDataFile"
              :loading="sandLoading"
              @click="submitUpload"
              >上 传</el-button
            >
          </div>
        </template>
      </div>
    </div>
    <el-backtop :bottom="100" :visibility-height="300"></el-backtop>
  </div>
</template>

<script>
import { getToken } from "@/common/cache"
import { isIdCard, isPhone } from "@/common/validate"
var checkIdcard = (rule, value, callback) => {
	if (value === "") {
		callback(new Error("请输入身份证号"))
	} else if (!isIdCard(value)) {
		callback(new Error("请输入正确的身份证号"))
	} else {
		callback()
	}
}
var checkMobile = (rule, value, callback) => {
	if (value === "") {
		callback(new Error("请输入手机号码"))
	} else if (!isPhone(value)) {
		callback(new Error("请输入正确的手机号码"))
	} else {
		callback()
	}
}
export default {
	name: "BulkImport",
	data() {
		return {
			sandLoading: false,
			importType: "", // 批量导入的数据类型
			reward_id: 0,
			baseApi: process.env.VUE_APP_API,
			// baseApi: 'https://zlh.zhiluinfo.com',
			headers: {
				Authorization: "Bearer " + getToken()
			},
			hasFile: false, // excel文件
			hasDataFile: false, // 压缩包文件
			steps: 1,
			excelData: {},
			import_id: "",
			disabled: false,
			forms: {
				tableData: []
			},
			rules: {
				realname: [ { required: true, message: "", trigger: "blur" } ],
				mobile: [ { validator: checkMobile, trigger: "blur" } ],
				idcard: [ { validator: checkIdcard, trigger: "blur" } ],
				total_amount: [ { required: true, message: "", trigger: "blur" } ],
				dept_name: [ { required: true, message: "", trigger: "blur" } ],
				job_name: [ { required: true, message: "", trigger: "blur" } ],
				type: [ { required: true, message: "", trigger: "change" } ],
				method: [ { required: true, message: "", trigger: "blur" } ],
				start_time: [ { required: true, message: "", trigger: "blur" } ],
				end_time: [ { required: true, message: "", trigger: "blur" } ],

				apply_type: [ { required: true, message: "", trigger: "blur" } ],
				apply_name_1: [ { required: true, message: "", trigger: "blur" } ],
				apply_name_2: [ { required: true, message: "", trigger: "blur" } ],
				apply_name_3: [ { required: true, message: "", trigger: "blur" } ]
			}
		}
	},
	created() {
		this.importType = this.$route.params.type
		this.reward_id = this.$route?.query?.reward_id || 0
		if (this.importType == "dimission") {
			this.$route.meta.title = "离职办理"
		} else if (this.importType == "reward") {
			this.$route.meta.title = "灵活用工/管理任务"
		} else {
			this.$route.meta.title = "工商办理"
		}
	},
	methods: {
		// Excel格式判断
		onChange(file, fileList) {
			let extension = file.name.substring(file.name.lastIndexOf(".") + 1)
			let size = file.size / 1024 / 1024
			if (extension !== "xlsx" && extension !== "xls") {
				this.$message.warning("请上传格式为 .xlsx 或 .xls 的文件")
				this.$refs.upload.clearFiles()
				return false
			}
			if (size > 5) {
				this.$message.warning("文件大小不能超过5M")
				this.$refs.upload.clearFiles()
				return false
			}
			if (fileList.length > 1) {
				fileList.shift()
			}
			if (fileList.length != 0) {
				this.hasFile = true
			} else {
				this.hasFile = false
			}
		},
		onError() {
			this.$message.warning("文件上传失败")
			this.sandLoading = false
		},
		onRemove() {
			this.hasFile = false
		},
		// Excel上传成功
		handleUploadSuccess(res) {
			if (res.code === 200) {
				let data = res.data
				data.list.forEach((item) => {
					item.realname = this.textFilter(item.realname)
				})
				this.excelData = data
				this.import_id = res.data.import_id || ""
				if (this.importType == "bussiness") {
					res.data.list.forEach((item) => {
						if (item.apply_type == "个体工商户") {
							item.apply_type = "1"
						} else if (item.apply_type == "个人独资企业") {
							item.apply_type = "2"
						}
					})
				}
				this.sandLoading = false
				this.forms.tableData = res.data.list
				this.steps++
			} else {
				this.hasFile = false
				this.hasDataFile = false
				this.sandLoading = false
				this.$refs.upload.clearFiles()
				this.$message.error(res.msg)
			}
		},
		// 下一步
		handleNext() {
			this.sandLoading = true
			this.$refs.upload.submit()
		},
		// 上一步
		handlePre() {
			this.forms.tableData = []
			this.steps--
			this.sandLoading = false
			this.hasFile = false
			this.disabled = false
		},

		// Excel数据导入
		ruleForms(api) {
			this.sandLoading = true

			let formData = this.forms.tableData

			let data = { import: formData }
			if (this.import_id) {
				// 工商批量导入时需带id
				data.import_id = this.import_id
			}
			if (this.reward_id) data.reward_id = this.reward_id // 灵活用工批量导入时需带id
			this.$axios({
				url: `/ent/v3/${this.importType}/import/${api}`,
				method: "POST",
				data,
				hideLoading: true,
				shake: true
			}).then((res) => {
				setTimeout(() => {
					this.sandLoading = false
				}, 900)
				if (res.code === 200) {
					this.excelData = res.data
					this.forms.tableData = res.data.list
					if (!res.data.error_num && api == "before_check") {
						this.disabled = true
					}
					if (!res.data.error_num && api == "batch_add") {
						this.steps++
					}
				}
			})
		},

		backList() {
			// this.$router.replace('/dimission')
			this.$router.go(-1)
		},
		// 资料格式判断
		dataFileChange(file, fileList) {
			let size = file.size / 1024 / 1024
			var FileExt = file.name.replace(/.+\./, "")
			if ([ "zip", "rar" ].indexOf(FileExt.toLowerCase()) === -1) {
				this.$message.warning("请上传格式为 .zip 或 .rar 的文件")
				this.$refs.dataUpload.clearFiles()
				return false
			}
			if (size > 200) {
				this.$message.warning("文件大小不能超过200M")
				this.$refs.dataUpload.clearFiles()
				return false
			}
			this.hasDataFile = true
			if (fileList.length > 1) {
				fileList.shift()
			}
		},
		submitUpload() {
			this.sandLoading = true
			this.$refs.dataUpload.submit()
		},
		// 资料上传成功
		dataUploadSuccess(res) {
			if (res.code === 200) {
				this.sandLoading = false
				this.$message.success("资料上传成功！")
				this.backList()
			} else {
				this.sandLoading = false
				this.$refs.dataUpload.clearFiles()
				this.$message.error(res.msg)
			}
		},
		onDataRemove() {
			this.hasDataFile = false
		}
	}
}
</script>

<style lang="less" scoped>
@import "~@/assets/style/upload.less";

.tip-box {
  width: 670px;
  margin: 10px auto;
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 20px 50px;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #606266;
}
</style>
